import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
export default class ToolbarSearch extends Vue {
  searchText = '';

  handleText(): void {
    this.$emit('on-text-change', this.searchText);
  }
}
