import { Vue, Component, Prop } from 'vue-property-decorator';
import Paginate from 'vuejs-paginate';

@Component({
  components: {
    Paginate,
  },
})
export default class ToolbarPagination extends Vue {
  @Prop({ default: 0 })
  totalPages!: number;

  handlePagination(page: number): void {
    this.$emit('on-page-change', page);
  }
}
