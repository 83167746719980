import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class ToolbarFilters extends Vue {
  arrowProps: { rotation?: number } = { };

  @Prop({ default: 'DESC' })
  order: 'DESC' | 'ASC' = 'DESC';

  @Watch('order')
  onChangeOrder(newOrder: 'DESC' | 'ASC', oldOrder: 'DESC' | 'ASC'): void {
    if (newOrder !== oldOrder) {
      if (!this.arrowProps.rotation) {
        this.arrowProps.rotation = 180;
      } else {
        delete this.arrowProps.rotation;
      }

      // Vue's reactivity caveat...
      this.$forceUpdate();
    }
  }

  changeRotation(): void {
    this.$emit('on-change-rotation', this.order === 'ASC' ? 'DESC' : 'ASC');
  }
}
